export const GET_ALL_POKEMONS = "GET_ALL_POKEMONS";
export const GET_POKEMON_DETAIL_BY_URL = "GET_POKEMON_DETAIL_BY_URL";
export const SET_TERM = "SET_TERM";
export const SEARCH_POKEMON = "SEARCH_POKEMON";
export const SEARCH_POKEMON_ID = "SEARCH_POKEMON_ID";
export const SET_FILTER = "SET_FILTER";
export const SET_FILTER_PICKER = "SET_FILTER_PICKER";
export const GET_POKEMON_BY_TYPE = "GET_POKEMON_BY_TYPE";
export const SET_ORDER = "SET_ORDER";
export const GET_POKEMON_BY_ORDER = "GET_POKEMON_BY_ORDER";
export const SET_PAGE = "SET_PAGE";
export const UPDATE_LOADING = "UPDATE_LOADING";
export const SET_ERR = "SET_ERR";
export const RESET_POKEMONS = "RESET_POKEMONS";
